document.addEventListener('DOMContentLoaded', async function () {
    let sessionToken = null;
    let cardBrands = {};

    const showError = (message) => {
        const errorElement = document.createElement('div');
        errorElement.style.position = 'fixed';
        errorElement.style.top = '0';
        errorElement.style.left = '0';
        errorElement.style.width = '100%';
        errorElement.style.height = '100%';
        errorElement.style.display = 'flex';
        errorElement.style.textAlign = 'center';
        errorElement.style.alignItems = 'center';
        errorElement.style.justifyContent = 'center';
        errorElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        errorElement.style.color = '#fff';
        errorElement.style.zIndex = '9999';
        errorElement.innerHTML = `<div><h2>Error</h2><p>${message}</p></div>`;
        document.body.appendChild(errorElement);
    };

    const getTokenFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('token') || null;
    };

    const validateSession = async () => {
        try {
            const apiBaseUrl = process.env.API_URL || 'http://localhost:8016';
            const response = await fetch(`${apiBaseUrl}/api/sessions/validate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: sessionToken }),
            });

            const result = await response.json();

            if (!result.success) {
                throw new Error(result.message || 'Token se sesión inválido.');
            }
            cardBrands = result.cardBrands;
        } catch (error) {
            console.error('Error validating session:', error);
            showError(error.message || 'Token se sesión inválido. Por favor recargue la página.');
            throw error; // Detener ejecución si la validación falla
        }
    };

    // Capturar el token desde la URL
    sessionToken = getTokenFromUrl();

    if (!sessionToken) {
        showError('Session token is missing. Please provide a valid session.');
        return; // Detener ejecución si no hay token
    }

    try {
        // Validar la sesión antes de continuar
        await validateSession();

        // Inicializar el widget aquí si la sesión es válida
        initializeWidget(sessionToken, cardBrands);
    } catch (error) {
        console.error('Widget initialization failed:', error);
    }
});

const initializeWidget = (sessionToken, cardBrands) => {
    console.log('Widget initialized with valid session!');
    const apiBaseUrl = process.env.API_URL || 'http://localhost:8016';

    // Card elements
    const card = document.getElementById('card');
    const cardNumberDisplay = document.getElementById('card-number-display');
    const cardHolderDisplay = document.getElementById('card-holder-display');
    const cardExpiryDisplay = document.getElementById('card-expiry-display');
    const cvvDisplay = document.getElementById('cvv-display');
    const bankLogo = document.getElementById('bank-logo');
    const cardBrand = document.getElementById('card-brand');

    // Form elements
    const cardNumberInput = document.getElementById('card-number-input');
    const cardHolderInput = document.getElementById('card-holder-input');
    const expiryDateInput = document.getElementById('expiry-date-input');
    const cvvInput = document.getElementById('cvv-input');
    const documentNumberInput = document.getElementById('document-number-input');
    const submitButton = document.getElementById('submit-button');
    const cardForm = document.getElementById('card-form');

    // Validation patterns
    const cardPatterns = {
        cardNumber: /^[0-9\s]{19}$/,  // 16 digits + 3 spaces
        cardHolder: /^[A-Z\s]{2,30}$/,
        expiryDate: /^(0[1-9]|1[0-2])\/\d{2}$/,
        cvv: /^[0-9]{3,4}$/
    };

    let lastFirstDigit = '';
    let lastBin = '';
    let lastKeyWasDelete = false;

    // Dispatch event when the widget starts
    window.parent.postMessage({ action: 'cp:widget_start', data: {} }, '*');

    expiryDateInput.addEventListener('keydown', function(event) {
        lastKeyWasDelete = event.key === 'Backspace' || event.key === 'Delete';
    });

    // Show success message
    const showSuccessMessage = () => {
        const successMessage = document.getElementById('success-message');
        successMessage.classList.add('show');
    };

    const closeWidget = () => {
        const widgetModal = document.getElementById('widget-modal');
        if (widgetModal) {
            widgetModal.style.display = 'none';
        }
    };

    // Animation for submit button
    const animateSubmitButton = (success) => {
        if (success) {
            submitButton.classList.remove('progress');
            submitButton.classList.add('completed');
            setTimeout(showSuccessMessage, 600); // Esperar que el botón se complete y luego mostrar el mensaje
        } else {
            submitButton.classList.remove('progress');
            submitButton.classList.add('error');
            setTimeout(() => {
                submitButton.classList.remove('error');
                submitButton.disabled = false;
            }, 1000);
        }
    };

    const showFeedbackMessage = (message) => {
        const feedbackMessageElement = document.getElementById('feedback-message');
        const feedbackTextElement = feedbackMessageElement.querySelector('span');
    
        // Si no hay mensaje, usar el mensaje por defecto
        feedbackTextElement.textContent = message || "Ha ocurrido un error.";
    
        // Dispatch event when the tokenization fails
        window.parent.postMessage({ action: 'cp:tokenization_failure', data: {
            error: message || "Ha ocurrido un error."
        } }, '*');

        // Mostrar el mensaje
        feedbackMessageElement.classList.remove('d-none');
        feedbackMessageElement.classList.add('show');
    };

    // Format card number with spaces and dots
    const formatCardNumber = (number) => {
        let formatted = number.match(/.{1,4}/g)?.join(' ') || '';
        while (formatted.length < 19) {
            if ((formatted.length + 1) % 5 === 0) {
                formatted += ' ';
            } else {
                formatted += '•';
            }
        }
        return formatted;
    };

    // Format expiry date with dots
    const formatExpiryDate = (date) => {
        let formatted = date.length > 2 ? date.slice(0, 2) + '/' + date.slice(2) : date;
        while (formatted.length < 5) {
            if (formatted.length === 2) {
                formatted += '/';
            } else {
                formatted += '•';
            }
        }
        return formatted;
    };

    // Update submit button state
    const updateSubmitButtonState = () => {
        const isFormValid = 
            cardPatterns.cardNumber.test(cardNumberInput.value) &&
            cardPatterns.cardHolder.test(cardHolderInput.value) &&
            cardPatterns.expiryDate.test(expiryDateInput.value) &&
            cardPatterns.cvv.test(cvvInput.value) &&
            documentNumberInput.value.trim() !== '';
        
        submitButton.disabled = !isFormValid;
    };

    // Update card preview and button state
    const updateCardPreviewAndButtonState = () => {
        updateCardPreview();
        updateSubmitButtonState();
    };

    // Update card preview
    const updateCardPreview = () => {
        let cardNumber = cardNumberInput.value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        let formattedCardNumber = formatCardNumber(cardNumber);
        cardNumberDisplay.textContent = formattedCardNumber;

        let cardHolder = cardHolderInput.value.toUpperCase();
        if (cardHolder.length > 20) {
            cardHolder = cardHolder.slice(0, 20) + '...';
        }
        cardHolderDisplay.textContent = cardHolder || 'NOMBRE DEL TITULAR';

        let expiryDate = expiryDateInput.value.replace(/[^0-9]/g, '');
        let formattedExpiryDate = formatExpiryDate(expiryDate);
        cardExpiryDisplay.textContent = formattedExpiryDate;

        cvvDisplay.textContent = cvvInput.value.padEnd(3, '•');
    };

    // Fetch BIN info
    const fetchBinInfo = (bin) => {
        return;
        fetch(`${apiBaseUrl}/api/bin-info/${bin}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    updateCardInfo(data);
                } else {
                    console.error('Información del BIN no encontrada.');
                }
            })
            .catch(error => {
                console.error('Error al buscar información del BIN:', error);
            });
    };

    // Update card info with BIN data
    const updateCardInfo = (data) => {
        if (data.bank) {
            bankLogo.innerHTML = `<img src="${data.bank.logo}" alt="${data.bank.name}">`;
        } else {
            bankLogo.innerHTML = ''; // Default or empty if not found
        }

        if (data.brand) {
            updateCardBrand(data.brand.logo);
        } else {
            cardBrand.innerHTML = ''; // Default or empty if not found
        }

        card.style.backgroundColor = data.color || 'gray'; // Default color if not found
    };

    // Determine card brand based on the first digit
    const determineCardBrand = (cardNumber) => {
        const firstDigit = cardNumber.charAt(0);
        if (firstDigit !== lastFirstDigit) {
            lastFirstDigit = firstDigit;
            if (cardBrands[firstDigit]) {
                updateCardBrand(cardBrands[firstDigit].logo);
            } else {
                cardBrand.innerHTML = ''; // Default or empty if not found
            }
        }
    };

    // Update card brand
    const updateCardBrand = (logo) => {
        cardBrand.innerHTML = `<img src="/img/brands/${logo}" alt="Card Brand" />`;
    };

    // Format and validate card number input
    cardNumberInput.addEventListener('input', function() {
        let cardNumber = cardNumberInput.value.replace(/[^\d]/g, ''); // Remove non-digits
        
        // Determine card brand if first digit changed
        determineCardBrand(cardNumber);

        // Fetch BIN info if the first 6 digits changed
        if (cardNumber.length >= 6) {
            let bin = cardNumber.slice(0, 6);
            if (bin !== lastBin) {
                lastBin = bin;
                fetchBinInfo(bin);
            }
        }

        cardNumber = cardNumber.slice(0, 16); // Limit to 16 digits
        let formattedCardNumber = formatCardNumber(cardNumber); // Apply format with spaces and dots
        cardNumberInput.value = formattedCardNumber.replace(/•/g, '').trim(); // Remove dots from input
        updateCardPreviewAndButtonState();
    });

    // Format and validate card holder input
    cardHolderInput.addEventListener('input', function() {
        cardHolderInput.value = cardHolderInput.value.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
        updateCardPreviewAndButtonState();
    });

    // Format and validate expiry date input
    expiryDateInput.addEventListener('input', function() {
        expiryDateInput.setCustomValidity('');

        let expiryDate = expiryDateInput.value.replace(/[^0-9]/g, '').slice(0, 4);
        
        if (expiryDate.length > 2 || (!lastKeyWasDelete && expiryDate.length == 2)) {
            expiryDate = expiryDate.slice(0, 2) + '/' + expiryDate.slice(2);
        }

        if (expiryDate.length == 5){
            const currentDate = new Date();
            const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Current month in MM format
            const currentYear = currentDate.getFullYear().toString().slice(2); // Current year in YY format
    
            const enteredMonth = expiryDate.slice(0, 2);
            const enteredYear = expiryDate.slice(3);
    
            if (enteredMonth > 12 || enteredMonth <= 0 || enteredYear < currentYear || (enteredYear === currentYear && enteredMonth < currentMonth)) {
                expiryDateInput.setCustomValidity('La fecha de vencimiento no puede ser anterior al mes actual');
                expiryDateInput.classList.add('is-invalid');
            } else {
                expiryDateInput.setCustomValidity('');
                expiryDateInput.classList.remove('is-invalid');
            }
        }

        expiryDateInput.value = expiryDate;

        updateCardPreviewAndButtonState();
    });

    // Validate document number input
    documentNumberInput.addEventListener('input', function() {
        documentNumberInput.value = documentNumberInput.value.replace(/[^0-9]/g, '');
        updateCardPreviewAndButtonState();
    });

    // Show card back on CVV focus
    cvvInput.addEventListener('focus', function() {
        document.getElementById('card-display').classList.add('flipped');
    });

    // Show card front on CVV blur
    cvvInput.addEventListener('blur', function() {
        document.getElementById('card-display').classList.remove('flipped');
    });

    // Format and validate CVV input
    cvvInput.addEventListener('input', function() {
        cvvInput.value = cvvInput.value.replace(/[^0-9]/g, '').slice(0, 4);
        updateCardPreviewAndButtonState();
    });

    // Validate form before submitting
    cardForm.addEventListener('submit', function(event) {
        event.preventDefault();
        let valid = true;

        if (!cardPatterns.cardNumber.test(cardNumberInput.value)) {
            cardNumberInput.classList.add('is-invalid');
            valid = false;
        } else {
            cardNumberInput.classList.remove('is-invalid');
        }

        if (!cardPatterns.cardHolder.test(cardHolderInput.value)) {
            cardHolderInput.classList.add('is-invalid');
            valid = false; 
        } else {
            cardHolderInput.classList.remove('is-invalid');
        }

        if (!cardPatterns.expiryDate.test(expiryDateInput.value)) {
            expiryDateInput.classList.add('is-invalid');
            valid = false;
        } else {
            expiryDateInput.classList.remove('is-invalid');
        }

        if (!cardPatterns.cvv.test(cvvInput.value)) {
            cvvInput.classList.add('is-invalid');
            valid = false;
        } else {
            cvvInput.classList.remove('is-invalid');
        }

        if (valid) {
            const cardData = {
                card_number: cardNumberInput.value.replace(/\s+/g, ''),
                card_holder: cardHolderInput.value,
                expiry_date: expiryDateInput.value,
                cvv: cvvInput.value,
                document_number: documentNumberInput.value,
                session_token: sessionToken
            };

            submitButton.classList.add('progress', 'progress-loading');
            submitButton.disabled = true;

            fetch(`${apiBaseUrl}/api/tokenize-card`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(cardData)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Dispatch event when the tokenization success
                    window.parent.postMessage({ action: 'cp:tokenization_success', data: data }, '*');
                    submitButton.style.transition = 'background-position 0.5s linear';
                    submitButton.classList.add('completed');
                    animateSubmitButton(true);
                    if (data.callback_url) {
                        setTimeout(() => { window.location.href = data.callback_url; }, 6000);
                    }
                } else {
                    submitButton.style.transition = 'background-position 0.5s linear';
                    animateSubmitButton(false);
                    console.error('Tokenization failed:', data.message);
                    showFeedbackMessage(data.message);
                }
            })
            .catch(error => {
                submitButton.style.transition = 'background-position 0.5s linear';
                animateSubmitButton(false);
                showFeedbackMessage();
                console.error('Error during tokenization:', error);
            });
        }
    });

    // Initialize submit button state
    updateSubmitButtonState();

};

